<template>
  <h1>{{ playlist.name }}</h1>
  {{ message }}
  <form @submit.prevent="onSubmit">
    <button @click="random">
      random
    </button>
    <section
      v-for="(category, cIndex) in categories"
      :key="category._id"
      class="my-4"
    >
      <h3>
        {{ category.name }} {{ recipe[category._id] }}
        <small class="text-muted fs-5">{{ category.min }}-{{ category.max }}</small>
      </h3>
      <div>
        <span v-for="(other, oIndex) in category.others" :key="other._id">
          <span v-if="oIndex != 0"> / </span>
          {{ other.num }} ({{ other.weight }})
        </span>
      </div>
      <div
        v-for="item in category.values"
        :key="item._id"
        class="form-check-inline my-2"
      >
        <span v-if="category.type == 'radio'">
          <input
            :id="'_' + item._id"
            v-model="recipe[category._id]"
            :value="item._id"
            class="btn-check"
            :type="category.type"
            autocomplete="off"
          >
          <label class="btn" :class="category.color" :for="'_' + item._id">
            {{ item.name }}
          </label>
        </span>
        <span v-else>
          <input
            :id="'_' + item._id"
            v-model="recipe[category._id]"
            :value="item._id"
            :name="'meat-' + item._id"
            :disabled="isDisabled(category, cIndex, item)"
            class="btn-check"
            :type="category.type"
            autocomplete="off"
          >
          <label class="btn" :class="category.color" :for="'_' + item._id">
            {{ item.name }}
          </label>
        </span>
      </div>
    </section>

    <button type="submit" class="btn btn-primary">
      Submit
    </button>
  </form>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  name: "Pizza",
  components: {
    //HelloWorld
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    playlistId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      recipe: {},
      categories: [],
      playlist: {},
      playlists: {},
      message: ""
    };
  },
  mounted() {
    //console.log("mounted", this.id);
    this.load();
  },
  methods: {
    onSubmit: async function () {
      const baseCategory = this.categories.find(
        (category) => category.name == "base"
      );
      const base = this.recipe[baseCategory._id];
      const ingredients = [].concat
        .apply([], Object.values(this.recipe))
        .filter((i) => i != base);

      const pizza = {
        base: base,
        ingredients: ingredients,
      };
      //console.log(pizza);
      //console.log("-this.id ", this.id);
      let pizzaId = this.id;
      if (this.id) {
        await axios.put(`/api/rest/dao/pizza/${this.id}/`, pizza);
      } else {
        const { data: body } = await axios.post(`/api/rest/dao/pizza/`, pizza);
        pizzaId = body._id;
      }

      /*
      console.log({
        playlistId: this.playlistId,
        pizzaId: pizzaId,
      });
      */
      await axios.post(`api/rest/add_pizza_playlist`, {
        playlistId: this.playlistId,
        pizzaId: pizzaId,
      });

      return router.back();
    },
    isDisabled: function (category, index, item) {
      return (
        this.recipe[category._id].length >= category.max &&
        this.recipe[category._id].indexOf(item._id) === -1
      );
    },

    random: async function () {
      this.load(true);
    },
    load: async function (random = false) {
      try {
        const colors = [
          "btn-outline-primary",
          "btn-outline-warning",
          "btn-outline-success",
          "btn-outline-danger",
          "btn-outline-info",
          "btn-outline-secondary",
        ];

        this.categories = [];

        const [{ data: categories }, { data: ingredients }] = await Promise.all(
          [
            axios.get("api/rest/dao/category"),
            axios.get("api/rest/dao/ingredient"),
          ]
        );

        this.categories = categories.map((category, index) => {
          category.values = ingredients.filter(
            (i) => i.category == category._id
          );
          category.color = colors[index % colors.length];
          category.type =
            category.min == 1 && category.max == 1 ? "radio" : "checkbox";
          this.recipe[category._id] = category.type == "radio" ? "" : [];
          return category;
        });

        if (this.playlistId) {
          this.playlist = (
            await axios.get(`/api/rest/dao/playlist/${this.playlistId}/`)
          ).data;
        }

        let pizza = false;
        if (this.id) {
          ({ data: pizza } = await axios.get(
            `/api/rest/dao/pizza/${this.id}?_populate=ingredients`
          ));
        }
        if (random) {
          ({ data: pizza } = await axios.get("api/rest/random"));
          this.message = `Base: ${pizza.base.name}, `;
          this.message += pizza.ingredients.map(i => i.name).join(", ");
          pizza.base = pizza.base._id;
        }
        if (pizza) {
          //console.log("recipeIngredients", pizza);
          pizza.ingredients.forEach((ingredient) => {
            if (this.recipe[ingredient.category])
              this.recipe[ingredient.category].push(ingredient._id);
          });

          // TODO: base must have its own class (extend ingredient)
          const base = this.categories.find(
            (category) => category.name == "base"
          );
          if (base) {
            this.recipe[base._id] = pizza.base;
          }
        }

        //console.log("categories", this.categories);
        //console.log("recipe", this.recipe);
      } catch (err) {
        console.error("ERROR::::: ", err);
        this.errors = err;
      }
    },
  },
};
</script>
