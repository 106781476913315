<template>
  <div :style="{ backgroundColor: bgColor}">
    <slot />
  </div>
</template>
<script>
export default ({
  data() {
    return {
      bgColor:'transparent'
    }
  },
  mounted() {
    /*
    this.$on('body-background-color', function(color) {
        this.bgColor = color;
    });
    */
}
})
</script>
