import { createRouter, createWebHashHistory } from 'vue-router'

import Rezept from '../components/settings/Rezept.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: () => import(/* webpackChunkName "Home" */ '@/views/AppSelect.vue'),
    }
  },
  {
    path: '/service',
    name: 'Service',
    //redirect: { name: 'ServiceTest' },
    components: {
      default: () => import(/* webpackChunkName "Service" */ '@/views/service/ServiceApp.vue'),
    },
  },
  {
    path: '/player/',
    name: 'Player',
    component: () => import(/* webpackChunkName "Player" */ '@/views/player/PlayerApp.vue'),

  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName "Settings" */ '@/views/settings/SettingsApp.vue'),
    children: [
      {
        path: 'playlist',
        name: 'Playlists',
        components: {
          default: () => import(/* webpackChunkName "Playlists" */ '@/components/settings/Playlists.vue'),
        }
      },
      {
        path: 'playlist/edit/:id',
        name: 'PlaylistEdit',
        props: true,
        components: {
          default: () => import(/* webpackChunkName "PlaylistEdit" */ '@/components/settings/Playlist.vue'),
        }
      },
      {
        path: 'playlist/new/',
        name: 'PlaylistNew',
        props: {
          id: "-1"
        },
        component: () => import(/* webpackChunkName "PlaylistNew" */ '@/components/settings/Playlist.vue'),
      },
      {
        path: 'playlist/:id',
        name: 'Playlist',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (book.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName "Playlist" */ '@/components/settings/Rezepte.vue'),
      },
      {
        path: 'pizza/edit/:playlistId/:id?',
        name: 'PizzaEdit',
        props: true,
        component: () => import(/* webpackChunkName "PizzaEdit" */ '@/components/settings/Rezept.vue'),
        __meta: {
          layout: 'Split'
        }
      },{
        path: 'ingredients/',
        name: 'Ingredients',
        component: () => import(/* webpackChunkName "Ingredients" */ '@/components/settings/Ingredients.vue'),
        __meta: {
          layout: 'Split'
        }
      },{
        path: 'users/',
        name: 'User.List',
        component: () => import(/* webpackChunkName "User.List" */ '@/components/settings/user/list.vue'),
        __meta: {
          layout: 'Split'
        }
      },
      {
        path: 'users/:id?',
        name: 'User.Edit',
        props: true,
        component: () => import(/* webpackChunkName "User.Edit" */ '@/components/settings/user/edit.vue'),
      },]
  },

  

  
  {
    path: '/pizza/',
    name: 'Pizza',
    // route level code-splitting
    // this generates a separate chunk (pizza.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "Rezept" */ '../views/Rezept.vue')
    component: Rezept
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
