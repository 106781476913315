<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import DefaultLayout from "./DefaultLayout";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "RootLayout",
  setup() {
    const layout = shallowRef(DefaultLayout);
    const route = useRoute();
    watch(
      () => route.meta,
      async (meta) => {
        layout.value = await (async () => {
          if (!meta.layout) {
            return DefaultLayout;
          }
          try {
            const component = await require(`@/layouts/${meta.layout}.vue`);
            return component?.default || DefaultLayout;
          } catch (e) {
            return DefaultLayout;
          }
        })();
      }
    );
    return { layout };
  },
};
</script>
