<template>
  <!-- Custom styles for this template -->
  <link href="css/custom.css" rel="stylesheet">
  
  <div
    id="nav"
    class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
    style="width: 280px"
  >
    <router-link
      :to="{ name: 'Home'}"
      class="
        d-flex
        align-items-center
        mb-3 mb-md-0
        me-md-auto
        text-white text-decoration-none
      "
    >
      <span class="fs-4">SO Pizza</span>
    </router-link>
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <router-link
          :to="{ name :'BookList'}"
          class="nav-link text-white"
          aria-current="page"
          active-class="active"
        >
          <svg
            class="bi me-2"
            width="16"
            height="16"
          >
            <use xlink:href="#home" />
          </svg>
          Cook Books
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'Player'}"
          class="nav-link text-white"
          active-class="active"
        >
          <svg
            class="bi me-2"
            width="16"
            height="16"
          >
            <use xlink:href="#speedometer2" />
          </svg>
          Player
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name :'Ingredients'}"
          class="nav-link text-white"
          aria-current="page"
          active-class="active"
        >
          <svg
            class="bi me-2"
            width="16"
            height="16"
          >
            <use xlink:href="#table" />
          </svg>
          Ingredients
        </router-link>
      </li>
      <!--
      <li>
        <a
          href="#"
          class="nav-link text-white"
        >
          <svg
            class="bi me-2"
            width="16"
            height="16"
          >
            <use xlink:href="#table" />
          </svg>
          Orders
        </a>
      </li>
      <li>
        <a
          href="#"
          class="nav-link active"
        >
          <svg
            class="bi me-2"
            width="16"
            height="16"
          >
            <use xlink:href="#grid" />
          </svg>
          Products
        </a>
      </li>
      <li>
        <a
          href="#"
          class="nav-link text-white"
        >
          <svg
            class="bi me-2"
            width="16"
            height="16"
          >
            <use xlink:href="#people-circle" />
          </svg>
          Customers
        </a>
      </li>
      -->
    </ul>
    <hr>
    <div class="dropdown">
      <a
        id="dropdownUser1"
        href="#"
        class="
          d-flex
          align-items-center
          text-white text-decoration-none
          dropdown-toggle
        "
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src="https://github.com/mdo.png"
          alt=""
          width="32"
          height="32"
          class="rounded-circle me-2"
        >
        <strong>mdo</strong>
      </a>
      <ul
        class="dropdown-menu dropdown-menu-dark text-small shadow"
        aria-labelledby="dropdownUser1"
      >
        <li>
          <a
            class="dropdown-item"
            href="#"
          >New project...</a>
        </li>
        <li>
          <a
            class="dropdown-item"
            href="#"
          >Settings</a>
        </li>
        <li>
          <a
            class="dropdown-item"
            href="#"
          >Profile</a>
        </li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li>
          <a
            class="dropdown-item"
            href="#"
          >Sign out</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="b-example-divider" />
  <div
    class="container-fluid py-4"
    style="overflow-y: auto"
  >
    <router-view />
  </div>
</template>

<style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>
